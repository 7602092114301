html {
  scroll-behavior: smooth;
}

// Container padding only kicks in, visually,
// when the entire view is 100% fluid, so lg and below.
// At that point, increase the container padding to 2rem,
// so it's not so tightly snuggled to the sides,
// and remove any nested .container padding so all containers,
// fluid or not, are nicely flush and aligned.
$container-padding: 2rem;

@include media-breakpoint-down(sm) {
  .container {
    max-width: 100% !important;
    padding-left: $container-padding !important;
    padding-right: $container-padding !important;
  }

  .container-fluid {
    padding-left: $container-padding !important;
    padding-right: $container-padding !important;
  }

  .container-fluid > .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// TYPE UTILS
//------------------------------------------------------------------//
.text-nowrap {
  white-space: nowrap;
}

.text-xxs {
  font-size: 0.7rem;
}

.text-xs {
  font-size: 0.8rem;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-lg * 1.2;
}

.text-red {
  color: $red;
}

.text-muted strong {
  color: $gray-600;
}

.text-strikethrough {
  text-decoration: line-through;
}

// CUSTOM SCROLLBAR
//------------------------------------------------------------------//
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: $gray-200;
}

::-webkit-scrollbar-thumb {
  background: $gray-400;
}

// CUSTOM SELECTION
//------------------------------------------------------------------//
::selection {
  color: $white;
  background: $primary;
}

// ANIMATED PAGE TRANSITIONS
//------------------------------------------------------------------//
$ease: cubic-bezier(0.64, 0, 0.78, 0); // easeInQuint
$duration: 500ms;

.fade-exit {
  position: fixed;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all $duration $ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
}

img.grayscale {
  filter: grayscale(1);
}

// SVG
//------------------------------------------------------------------//
.svg-clip-path {
  position: absolute;
  top: -100%;
  pointer-events: none;
}
