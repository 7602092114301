// MIXINS
//------------------------------------------------------------------//

@mixin color-utilities($property) {
  @each $key, $value in $theme-colors {
    &-#{$key} {
      #{$property}: $value !important;
    }
  }
}

.color {
  @include color-utilities(color);
  // GENERATES
  // .color-primary        { color: $primary; }
  // .color-primary-dark   { color: $primary-dark; }
  // .color-primary-darker { color: $primary-darker; }
  // ...
}

/* responsive version for sizes, we don't generate xs because it is arleady generated by bootstrap */
@each $breakpoint in map-keys($grid-breakpoints) {
  @if $breakpoint != 'xs' {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $prop, $abbrev in (width: w, height: h) {
        @each $size, $length in $sizes {
          .#{$abbrev}#{$infix}-#{$size} {
            #{$prop}: $length !important;
          }
        }
      }
    }
  }
}
